import { from } from "rxjs";

export * from "./otp/otp.widget";
export * from "./pharmacy-product/pharmacy-product.widget";
export * from "./barcode-scanner/barcode-scanner.widget";
export * from "./common-data/common-data.widget";
export * from "./documents/documents.widget";
export * from "./aside-appointments/aside-appointments.widget";
export * from "./file-dropper/file-dropper.widget";
export * from "./banner-setting/banner-setting.widget";
export * from "./new-lab-invoice/new-lab-invoice.widget";
export * from "./new-lab-parameter/new-lab-parameter.widget";
export * from "./new-lab-report/new-lab-report.widget";
export * from "./new-lab-timeline/new-lab-timeline.widget";
export * from "./timeline/timeline.widget";
export * from "./pharmacy-indent/pharmacy-indent.widget"
export * from "./aside-appointments/aside-appointments.widget"
export * from "./appointment-invoice/appointment-invoice.widget";
export * from "./pharmacy-header/pharmacy-header.widget";
export * from "./chat-box/chat-box.widget";
export * from "./color-identification/color-identification.widget";
export * from "./img-crop/img-crop.widget";
export * from "./img-capture/img-capture.widget";
export * from "./color-identification/color-identification.widget";
export * from "./img-crop/img-crop.widget";
export * from "./Ob-header/ob-header";
export * from "./signature/signature.widget";
export * from "./diet-header/diet-header.widget";
export * from "./gyn-header/gyn-header.widget";
export * from "./pediatric-header/pediatrics-header.page";
export * from "./receipt-view/receipt-view.widget";
export * from "./parameter-input/parameter-input.widget";
export * from "./genetic-speciality-encounter-header/genetic-header";
export * from "./physiotherapy-encounter-header/physiotherapy-header";
export * from "./emergency-header/emergency-header.widget";
export * from "./lab-status-identification/lab-status-identification.widget";
//export * from "./img-draw/img-draw.widget";
export * from "./nurse-header/nurse-header.page";
export * from "./test-chat-box/test-chat-box.widget";
export * from "./telemedicine/telemedicine.widget";
export * from "./messaging/messaging.widget";
export * from "./neonatal-header/neonatal-header.widget";
export * from "./investigations/investigation-exclusions.page";
export * from "./reports-footer-location/reports-footer-location.widget";
export * from "./registration-invoice/registration-invoice.widget";
export * from "./transferedLabs/transfered-labs.widget";
export * from "./insurance-documents/insurance-documents.widget";
export * from "./new-lab-modify/new-lab-modify.widget";
export * from "./gate-pass-report/gate-pass-report.widget";
export * from "./new-lab-cancelled-invoice/new-lab-cancelled-invoice.widget";
export * from "./graph-view/graph-view.widget";
export * from "./interim-report/interim-report.page";
export * from "./footer-setting/footer-setting.widget";
export * from "./nurse-select-bed/nurse-select-bed.widget";
export * from "./new-banner/new-banner-setting.widget";
export * from "./ivf-header/ivf-header.widget";
export * from "./ophthalmology-encounter-header/ophthalmology-encounter-header";
export * from "./pharmacy-invoice/pharmacy-invoice.widget";
export * from "./scan-invoice/scan-invoice.widget";
export * from "./new-lab-schema-report/new-lab-schema-report.widget";
export * from "./new-lab-schema-parameter/new-lab-schema-parameter.widget";
export * from "./new-lab-schema-interim-report/new-lab-schema-interim-report.widget"