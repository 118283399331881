<div>
    <div class="mb-2">
        <div class="px-3">
            <div class="col-12 p-0" *ngIf="isPrintLogo">
                <banner-setting [reportName]='"Pharmacy Receipt"'></banner-setting>
            </div>
            <div *ngIf="!isPrintLogo" class="col-12 p-0">
                <div>
                    <pharmacy-header></pharmacy-header>
                </div>
            </div>
            <div class="col-12 p-0 overflow-auto mt-2">
                <table width="100%" class="font-11">
                    <tr class="heading1">
                        <th class="text-center" colspan="6">
                            <b>Final Tax Invoice - OP Sales (Pharmacy)</b>
                        </th>
                    </tr>
                    <tr class="mb-2 border-top border-bottom font-13 border-dark">
                        <th class=" px-2">
                            <div class="white-space-nowrap">
                                Bill No
                            </div>
                        </th>
                        <th>
                            <span class="margin-Right">: </span>
                        </th>
                        <td>
                            <span [textContent]="bills[0].billNumber"></span>
                        </td>

                        <th class="w-10px white-space-nowrap pl-8">
                            <div>
                                D L No
                            </div>
                        </th>
                        <th>
                            <span class="margin-Right">: </span>
                        </th>
                        <td>
                            <span *ngIf="selectedStore.dlNumber" [textContent]="selectedStore.dlNumber"></span>
                            <span *ngIf="!selectedStore.dlNumber" [textContent]="'------------'"></span>
                        </td>
                    </tr>
                    <tr>
                        <th class=" px-2">
                            <div class="white-space-nowrap">
                                UMR No
                            </div>
                        </th>
                        <th>
                            <span class="margin-Right">: </span>
                        </th>
                        <td>
                            <span [textContent]="bills[0].umrNo || '-----'"></span>
                        </td>

                        <th class="pl-8">
                            <div>
                                GST No
                            </div>
                        </th>
                        <th>
                            <span class="margin-Right">: </span>
                        </th>
                        <td>
                            <span [textContent]="bills[0].gstNumber || '-----'"></span>
                        </td>
                    </tr>
                    <tr>
                        <th class="px-2 w-10px">
                            <div class="white-space-nowrap">
                                Patient Name
                            </div>
                        </th>
                        <th>
                            <span class="margin-Right">: </span>
                        </th>
                        <td>
                            <span class="text-uppercase" [textContent]="bills[0].patientName"></span>
                        </td>
                        <th class=" pl-8 bg-white text-left">
                            <div>
                                Date
                            </div>
                        </th>
                        <th>
                            <span class="margin-Right">: </span>
                        </th>
                        <td>
                            <span [textContent]="bills[0].saleDate | date:'dd/MM/yyyy'+ '   '"></span>

                            <span [textContent]="bills[0].saleDate | date:'h:mm a'"></span>
                        </td>
                    </tr>
                    <tr>
                        <th class="px-2 bg-white text-left  paddingLeft  ">
                            <div class="white-space-nowrap ">
                                Doctor Name
                            </div>
                        </th>
                        <th>
                            <span class="margin-Right">: </span>
                        </th>
                        <td>
                            <span [textContent]="bills[0].providerName "></span>
                        </td>
                        
                    </tr>
                </table>
            </div>
            <div class="col-12 mt-2 overflow-auto">
                <table width="100%" id="invoiceId-table-2-tr">
                    <tbody>
                        <tr class="border-top border-bottom border-dark font-weight-bolder">
                            <th class="px-1  border-top border-bottom border-dark mt-0">SNo.</th>
                            <th class="px-1  border-top border-bottom border-dark white-space-nowrap">HSN Code</th>
                            <th class="px-1  border-top border-bottom border-dark" style="width: 150px;">Medicine Name</th>
                            <th class="px-1  border-top border-bottom border-dark">SH</th>
                            <th class="px-1  border-top border-bottom border-dark">Mfg Nm</th>
                            <th class="px-1  border-top border-bottom border-dark">Batch No</th>
                            <th class="px-1  border-top border-bottom border-dark">Exp Dt</th>
                            <th class="px-1  border-top border-bottom border-dark">Qty</th>
                            <th class="px-1  border-top border-bottom border-dark">Dis</th>
                            <th class="px-1  border-top border-bottom border-dark">MRP</th>
                            <th class="px-1  border-top border-bottom border-dark">Taxable</th>
                            <th class="px-1  border border-dark">CGST </th>
                            <th class="px-1  border-top border-bottom border-right border-dark">SGST </th>

                            <th class="px-1  border-top border-bottom border-dark">Amount</th>
                        </tr>

                        <tr *ngFor="let bill of bills;index as i;" class="font-weight-bold">

                            <td class="px-1 text-center">{{i+1}}</td>
                            <td class="px-1 "><span [textContent]="bill.hsnCode"></span></td>
                            <td class="px-1  " style="width: 180px;"><div class="max-width100 min-width100px" [textContent]="bill.productName"></div></td>
                            <td class="px-1 "><span [textContent]="bill.scheduledDrug"></span></td>
                            <td class="px-1 "><div class="max-width100 min-width100px" [textContent]="bill.companyName"></div></td>
                            <td class="px-1 "><span [textContent]="bill.batchNumber"></span></td>
                            <td class="px-1 "><span [textContent]="bill.expiryDate | date:'dd/MM/yyyy'"></span></td>


                            <td class="px-1 text-center"><span [textContent]="bill.quantity"></span></td>
                            <td class="px-1"><span [textContent]="(bill.discount || 0) | currency: 'INR'"></span></td>
                            <td class="px-1"><span [textContent]="(bill.mrp || 0) | currency: 'INR'"></span></td>
                            <td class="px-1 "><span [textContent]="(bill.netAmount-bill.cgst-bill.sgst-bill.igst   || 0) | currency: 'INR'"></span></td>
                            <td class="px-1 ">
                                <span [textContent]="bill.cgst | currency:'INR'"></span>
                            </td>
                            <td class="px-1">
                                <span [textContent]="bill.sgst | currency:'INR'"></span>
                            </td>
                            <td class="px-1"><span [textContent]="(bill.netAmount || 0) | currency: 'INR'"></span></td>


                            <th *ngIf="bill.paymentType === 'P'" class="justify-content-center d-flex">
                                <div id="backgroundOfwaterMark" style=" -webkit-transform: rotate(-30deg); color:grey"> <span>Partial Paid</span></div>
                            </th>
                        </tr>
                        <tr id="invoiceId-table-2-tr" class="font-weight-bolder">
                            <td colspan="7" class="px-1; text-left border-top  border-dark">
                                <span class="text-capitalize" [textContent]="'Rupees : ' + amtInWords + ' Only'">  &nbsp;&nbsp; </span>
                            </td>
                            <td colspan="3" class="border-top px-1 border-bottom border-dark text-center">
                                <span [textContent]="'Sub Total : '"></span>
                            </td>
                            <td colspan="3" class="border-top px-1 border-bottom border-dark bg-white text-left">
                                <span *ngIf="gstCalculation[gstCalculation.length-1].gst === -1" style="display:inline-flex; justify-content: space-between">

                                    <span [textContent]="gstCalculation[gstCalculation.length-1].taxableAmount | currency:'INR'"></span> &nbsp; &nbsp;&nbsp;
                                    <span [textContent]="gstCalculation[gstCalculation.length-1].cgst | currency:'INR'"></span>&nbsp;&nbsp; &nbsp;
                                    <span [textContent]="gstCalculation[gstCalculation.length-1].sgst | currency:'INR'"></span>
                                </span>
                            </td>
                            <td colspan="1" class="px-1 text-left px-1 border-top border-bottom border-dark bg-white text-left">


                                <span [textContent]="bills[0].overallNetAmount | currency: 'INR'"></span>
                            </td>
                        </tr>
                        <tr>
                            <th class="bg-white text-left">
                                <div>
                                    Mode Of Payment
                                </div>
                            </th>
                        </tr>
                        <tr class="font-weight-bolder">
                            <td class="border-bottom border-dark">
                                PaymentType
                            </td>
                            <td class="border-bottom border-dark">
                                AmountPaid
                            </td>
                        </tr>
                        <tr *ngFor="let payment of bills[0].payTypes">
                            <td class="px-1 text-left ">
                                <span [textContent]="payment.payTypeName"></span>
                            </td>
                            <td class="px-1 text-right ">
                                <span [textContent]="(payment.amountPaid | currency: 'INR')"></span>
                            </td>
                        </tr>
                        <tr class="font-weight-bolder">
                            <td class="text-left border-bottom border-dark" colspan="7"><span [textContent]="'Note: Goods once sold will not be taken back'"></span></td>
                            <td colspan="3" class=" px-1 border-bottom border-dark px-1 text-center">
                                <span [textContent]="'Net Sale:'"></span>
                            </td>
                            <td class="paddingTop border-bottom border-dark px-1 bg-white" colspan="1">

                                <span [textContent]="'   ' + bills[0].overallNetAmount | currency: 'INR'"></span>
                            </td>
                            <td class="paddingTop border-bottom border-dark px-1 bg-white" colspan="2">
                                <span [textContent]="'Paid Amount :'"></span>
                            </td>
                            <td colspan="1" class="px-1 text-left border-bottom px-1 border-dark bg-white text-left">

                                <span [textContent]=" bills[0].paidAmount | currency: 'INR'"></span>

                            </td>
                        </tr>
                    </tbody>
                </table>


                <div class="col-12 p-0 overflow-auto font-12 font-weight-bold">
                    <span [textContent]="'Printed By : ' +page.userAccount.fullName  "></span> &nbsp;&nbsp;
                    <span [textContent]="date | date :'dd/MM/yyyy, h:mm a'"></span>
                </div>
            </div>

        </div>
        <!--<div *ngIf="isFooter">
            <banner-setting [reportName]='"Pharmacy Receipt Footer"'></banner-setting>
        </div>-->
    </div>

</div>