<div class="modal-body" *ngIf="loading">
    <div class="d-flex align-items-center">
        <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
        <span class="ml-2">Please wait while loading bill details....</span>
    </div>
</div>
<div class="modal-body" *ngIf="!loading && bill.length === 0">
    <div class="d-flex justify-content-center">
        <div class="no-data-container">
            <img class="mt-1" width="350" src="assets/images/no-data-2.png" alt="No Data" />
            <h4 class="mt-1">No Bill Data found.Nothing to show you.</h4>
        </div>
    </div>
</div>
<div Id="invoiceId" *ngIf="!loading && bill.length > 0">
    <div class="report" *ngFor="let bill of bill;index as i;">
        <div class="overflow-auto">
            <table width="100%">
                <tr *ngIf="isPrintLogo">
                    <td colspan="4" align="center">
                        <banner-setting [reportName]='"Lab Invoice Header"'></banner-setting>
                    </td>
                </tr>
                <tr *ngIf="!isPrintLogo">
                    <td colspan="2" class="height100"></td>
                </tr>
                <tr>
                    <td colspan="4">
                        <h4 class="text-center">Laboratory Receipt</h4>
                    </td>
                </tr>
                <tr class="vertical-align-top">
                    <th>
                        <h5 *ngIf="bill.patientName">Patient Name</h5>
                        <h5 *ngIf="bill.employeeName">Employee Name</h5>
                    </th>
                    <td class="d-flex">
                        <span class="mr5px">: </span>
                        <span class="text-uppercase d-block" *ngIf="bill.patientName" [textContent]="bill.patientName"></span>
                        <span class="text-uppercase d-block" *ngIf="bill.employeeName" [textContent]="bill.employeeName"></span>
                    </td>
                    <th>
                        UMR No
                    </th>
                    <td>
                        <span class="mr5px">: </span>
                        <span [textContent]="bill.umrNo || '-----'"></span>
                    </td>
                </tr>
                <tr class="vertical-align-top">
                    <th>
                        Doctor Name
                    </th>
                    <td class="d-flex">
                        <span class="mr5px">: </span>
                        <span class="d-block text-uppercase" [textContent]="bill.doctorName || '------'"></span>
                    </td>
                    <th>
                        Age/Gender
                    </th>
                    <td>
                        <span class="mr5px">: </span>
                        <span [textContent]="bill.age ? bill.age +' Years' : (bill.patientAge  || '----')"></span>
                        <span *ngIf="bill.gender"> / <span [textContent]="bill.gender | gender"></span></span>
                    </td>
                </tr>
                <tr class="vertical-align-top">
                    <th>
                        <h5>Invoice Date</h5>
                    </th>
                    <td>
                        <span class="mr5px">: </span>
                        <span [textContent]="bill.createdDate | date:'dd/MM/yyyy, h:mm a'"></span>
                    </td>
                    <th>
                        Requisition No
                    </th>
                    <td>
                        <span class="mr5px">: </span>
                        <span [textContent]="bill.requisitionNumber"></span>
                    </td>
                </tr>
                <tr class="vertical-align-top">
                    <th>
                        <h5>Mobile Number</h5>
                    </th>
                    <td>
                        <span class="mr5px">: </span>
                        <span [textContent]="bill.mobile || '----'"></span>
                    </td>
                    <th>
                        Billed Location
                    </th>
                    <td>
                        <span class="mr5px">: </span>
                        <span [textContent]="bill.locationName"></span>
                    </td>
                </tr>
            </table>
        </div>
        <div *ngIf="selectedPackage" class="mt-2 overflow-auto">
            <table class="w-100 border-collapse border mb-2">
                <thead>
                    <tr class="border-bottom">
                        <th class="border-right">S.No</th>
                        <th class="border-right">Package Name</th>
                        <th class="border-right">Discount</th>
                        <th class="border-right">Total Amount</th>
                        <th class="border-right">Net Amount</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="border-bottom" >
                        <td class="border-right">
                            <span class="mb-0 mt-0" [textContent]="'1'"></span>
                        </td>
                        <td class="border-right">
                            <h5 class="mb-0 mt-0" [textContent]="selectedPackage.packageName"></h5>
                        </td>
                        <td class="border-right">
                            <h5 class="mb-0 mt-0" [textContent]="selectedPackage.discountType =='A' ? (selectedPackage.discountAmount || 0 | currency:'INR') : (selectedPackage.discountPercentage + ' %')"></h5>
                        </td>
                        <td class="border-right">
                            <h5 class="mb-0 mt-0" [textContent]="totalPackageAmount || 0 | currency:'INR'"></h5>
                        </td>
                        <td class="border-right">
                            <h5 class="mb-0 mt-0" [textContent]="packageAmount || 0 | currency:'INR'"></h5>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="mt-2 overflow-auto">
            <table class="w-100 border-collapse border mb-2">
                <thead>
                    <tr class="border-bottom">
                        <th class="border-right">S.No</th>
                        <th class="border-right">TestName</th>
                        <th class="border-right">Status</th>
                        <th class="border-right" *ngIf="isShowTestDurationTime">Duration</th>
                        <th class="border-right" *ngIf="showFinanceandPrintByDetails">Total(&#8377;)</th>
                        <th class="border-right" *ngIf="showFinanceandPrintByDetails">Discount(&#8377;)</th>
                        <th *ngIf="showFinanceandPrintByDetails">NetAmnt(&#8377;)</th>
                    </tr>
                </thead>
                <tbody>
                    <tr class="border-bottom" *ngFor="let lab of bill.labs;index as j;">
                        <td class="border-right">
                            <span class="mb-0 mt-0" [textContent]="j+1"></span>
                        </td>
                        <td class="border-right">
                            <span class="mb-0 mt-0" [textContent]="lab.testName"></span>
                            <small class="d-block text-monospace" [textContent]="lab.departmentName || '--'"></small>
                        </td>
                        <td class="border-right">
                            <span class="mb-0 mt-0" [textContent]="lab.status"></span>
                        </td>
                        <td class="border-right" *ngIf="isShowTestDurationTime">
                            <span class="mb-0 mt-0" *ngIf="lab.testDurationTime !=null" [textContent]="lab.testDurationTime +' hrs'"></span>
                            <span class="text-center" *ngIf="lab.testDurationTime ==null">--</span>
                        </td>
                        <td class="border-right" *ngIf="showFinanceandPrintByDetails">
                            <span class="mb-0 mt-0" [textContent]="lab.isFromPackage ? '----' :lab.totalAmount || 0 | currency :'INR'"></span>
                        </td>
                        <td class="border-right" *ngIf="showFinanceandPrintByDetails">
                            <span class="mb-0 mt-0" [textContent]="lab.isFromPackage ? '----' :lab.discountAmount || 0 | currency :'INR'"></span>
                        </td>
                        <td *ngIf="showFinanceandPrintByDetails">
                            <span class="mb-0 mt-0" [textContent]="lab.isFromPackage ? '----' :lab.netAmount || 0 | currency :'INR'"></span>
                        </td>
                    </tr>
                    <tr class="border-bottom">
                        <td *ngIf="isNewlabinvoiceId" class="justify-content-center d-flex">
                            <!--Need To Use Internal CSS-->
                            <div id="backgroundOfwaterMark" style="-webkit-transform: rotate(-30deg);margin-top:-55px">
                                <span class=" text-danger font-bold"><b>Duplicate</b></span>
                            </div>

                        </td>
                    </tr>
                </tbody>
                <tfoot *ngIf="showFinanceandPrintByDetails">
                    <tr>
                        <td class="border-right" colspan="2">
                            <h5>Amount : {{bill.overallTotalAmount || 0 | currency:'INR' }}</h5>
                        </td>
                        <td class="border-right">
                            <h5>Disc : {{bill.overallDiscount || 0 | currency:'INR'}}</h5>
                        </td>
                        <td class="border-right" colspan="2">
                            <div class="d-flex justify-content-center">
                                <h5>NetAmt :{{bill.overallNetAmount || 0 | currency:'INR'}}</h5>
                            </div>
                        </td>
                        <td colspan="2">
                            <h5>Paid Amount : {{bill.paidAmount || 0 | currency:'INR' }}</h5>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
        <div class="d-flex flex-wrap justify-content-between" *ngIf="showFinanceandPrintByDetails">
            <div class="mt-1">
                <table width="100%" class="heading1">
                    <tr class="heading2">
                        <th>
                            Prepared By
                        </th>
                        <td colspan="3">
                            <span>: </span>
                            <span class="text-capitalize" [textContent]="bill.createdByName"></span>
                            <small class="border-success btn btn-xs font-weight-normal ml-1 px-1 text-black text-success" *ngIf="bill.paymentType === 'F'"><i class="mdi mdi-cash pr-1"></i>Fully Paid</small>
                            <small class="border-success btn btn-xs font-weight-normal ml-1 px-1 text-black text-success" *ngIf="bill.paymentType === 'P'" id="backgroundOfwaterMark" style=" -webkit-transform: rotate(-30deg); color:grey"> <span>Partial Paid</span></small>
                        </td>
                    </tr>
                    <tr class="heading2" *ngFor="let paytype of bill.payTypes">
                        <th [textContent]="paytype.payTypeName">
                        </th>
                        <td class="w-10px">
                            :
                        </td>
                        <td [textContent]="paytype.amountPaid || 0 | currency :'INR'">
                        </td>
                        <td [textContent]="paytype.receiptDate | date:'dd/MM/yyyy, h:mm a'">
                        </td>
                    </tr>
                    <tr class="heading2">
                        <th>
                            Printed By
                        </th>
                        <td colspan="3">
                            <span>: </span>
                            <span class="text-capitalize" [textContent]="page.userAccount?.fullName"></span>
                        </td>
                    </tr>
                    <tr class="heading2">
                        <th>
                            Printed Date
                        </th>
                        <td colspan="3">
                            <span>: </span>
                            <span [textContent]="currentDate | date:'dd/MM/yyyy, h:mm a'"></span>
                        </td>
                    </tr>
                </table>
            </div>
            <div class="flex-grow-1 align-content-around barcode">
                <ngx-barcode6 [bc-value]="bill.requisitionNumber" [bc-width]="2" [bc-height]="40"
                              [bc-display-value]="false">
                </ngx-barcode6>
            </div>
            <div class="flex-grow-1 text-right">
                <qrcode [qrdata]="QrCode" [width]="200" [errorCorrectionLevel]="'M'" [elementType]="'img'"></qrcode>
            </div>
        </div>
    </div>
    <div *ngIf="isFooter">
        <banner-setting [reportName]='"Lab Invoice Footer"'></banner-setting>
    </div>
</div>