export class Provider {

    providerId: number;
    encryptedProviderId?: string;
    salutation: string;
    firstName: string;
    middleName?: string;
    lastName: string;
    fullName: string;
    friendlyName: string;
    dateOfBirth?: Date;
    createdDate?: Date;
    age?: number;
    experience?: number;
    gender?: string;
    maritalStatus?: string;
    email: string;
    mobile?: string;
    phone: string;
    npi: string;
    prescriberNo?: string;
    providerNo?: string;
    healthIdentifierNo?: string;
    countryId: number;
    departmentId: number;
    countryName?: string;
    countryCode?: string;
    isoCode?: string;
    profileImageUrl?: string;
    profileThumbnailImageUrl?: string;
    thumbnailUrl?: string;
    services: any;
    specializations: any;
    serviceNames: any;
    specializationNames: any;
    awardsHonors: any;
    educations: any;
    memberships: any;
    registrations: any;
    experiences: any;
    addressLine: string;
    city: string;
    state: string;
    zipCode: string;
    about: string;
    consultationCharges: number;
    telemedicineCharges: number;
    telemedicineDuration: number;
    consultationDuration: number;
    providerLocationId: number;
    percentage: number;
    providerName: string;
    currencySymbol: string;
    practiceName: string;
    languages: string;
    theme: string;
    enableEmailAlerts: boolean;
    enableSMSAlerts: boolean;
    enableDesktopNotifications: boolean;
    enableMobileNotifications: boolean;
    isCouponApplied: boolean;
    isOnline: boolean;
    signature: string;
    clinicPicture: string;
    practiceLocationId: number;
    id: number;
    value: string;
    optionalText?: string;
    locationName?: string;
    locationId?: number;
    locationIdentifiers: string;
    userName: string;
    salutationId: number;
    salutationName: string;
    panCardNo?: string;
    employee?: string;
    type?: string;
    doctorType?: string;
    doctorTypes?: string;
    locationIdentifierNames: string;
    labDepartmentIds: number[];
    sessionType: number[];
}