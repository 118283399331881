<div class="modal-body" *ngIf="loading">
    <div class="d-flex align-items-center">
        <span class="spinner-grow text-warning" role="status" aria-hidden="true"></span>
        <span class="ml-2">Please wait while loading Report details....</span>
    </div>
</div>
<div *ngIf="!isExternalReport">
    <div class="modal-body" *ngIf="!loading && demographData.length === 0 && !isExternalReport">
        <div class="d-flex justify-content-center">
            <div class="no-data-container">
                <img class="mt-1" width="350" src="assets/images/no-data-2.png" alt="No Data" />
                <h4 class="mt-1">No Report Data found.Nothing to show you.</h4>
            </div>
        </div>
    </div>

    <div id="invoiceId" *ngIf="!loading && demographData.length > 0">
        <div class="report position-relative" *ngIf="reportsAll?.length > 0">
            <div class="page-footer-pdetail">
                <div class="h-100">
                    <div *ngIf="isFooter" class="border-top h-100 border-dark pb-1 px-1 left-0 right-0 background-color" [ngStyle]="{'background-color': (isPrintLogo ) ? 'white':'#dddddd'}">
                        <div class="h-100 p-1">
                            <banner-setting [reportName]='"Lab Report Footer"'></banner-setting>
                        </div>
                    </div>
                </div>
            </div>
            <table class="w-100 border-collapse table-layout-fixed">
                <thead>
                    <tr class="border-bottom border-dark">
                        <td>
                            <div class="text-center">
                                <div id="header-content" class="w-100">
                                    <div class="d-table-cell page-header-space mb-1 vertical-align-middle" *ngIf="isPrintLogo">
                                        <banner-setting [reportName]='"Lab Report Header"'></banner-setting>
                                    </div>
                                    <div *ngIf="!isPrintLogo" class="h-200px">

                                    </div>
                                    <div class="overflow-x-auto overflow-y-hidden white-space-nowrap">
                                        <table class="text-left w-100 line-height16">
                                            <tr class="vertical-align-top">
                                                <th>
                                                    <h5 class="white-space-nowrap" *ngIf="demographData[0].patientName">Patient Name</h5>
                                                </th>
                                                <td class="d-flex">
                                                    <span class="marginright5">: </span>
                                                    <span class="text-capitalize" *ngIf="demographData[0].patientName" [textContent]="demographData[0].patientName"></span>
                                                </td>
                                                <th>
                                                    <h5 class="white-space-nowrap">UMR No</h5>
                                                </th>
                                                <td class="white-space-nowrap">
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="demographData[0].umrNo || '-----'"></span>
                                                </td>
                                            </tr>
                                            <tr class="vertical-align-top">
                                                <th>
                                                    Age/Gender
                                                </th>
                                                <td>
                                                    <div class="d-flex">
                                                        <span class="marginright5">: </span>
                                                        <div>
                                                            <span *ngIf="!demographData[0].dateOfBirth">{{demographData[0].age}} Year &nbsp;0 Month&nbsp;0Days </span>
                                                            <span *ngIf="demographData[0].dateOfBirth">{{age.year}} Year &nbsp;{{age.month}} Month&nbsp;{{age.day}} Days </span>
                                                            <span *ngIf="demographData[0].gender">&nbsp;/</span>
                                                            <span *ngIf="demographData[0].gender" [textContent]="demographData[0].gender || '----'"></span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <th>
                                                    Invoice Date
                                                </th>
                                                <td class="white-space-nowrap">
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="demographData[0].bookedDate | date:'dd/MM/yyyy, h:mm a'"></span>
                                                </td>
                                            </tr>
                                            <tr class="vertical-align-top">
                                                <th>
                                                    {{relationType ? relationType : 'RelativeName' }}
                                                </th>
                                                <td>
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="patient?.relativeName"></span>
                                                </td>
                                                <th class="white-space-nowrap">
                                                    Requisition No
                                                </th>
                                                <td class="white-space-nowrap">
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="demographData[0].requisitionNumber"></span>
                                                </td>
                                            </tr>

                                            <tr class="vertical-align-top">
                                                <th>
                                                    Location
                                                </th>
                                                <td>
                                                    <span class="marginright5">: </span>
                                                    <span class="text-capitalize" [textContent]="demographData[0].fromLocation"></span>
                                                </td>
                                                <th class="white-space-nowrap">
                                                    Collected Date
                                                </th>
                                                <td>
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="demographData[0].collectionDate | date:'dd/MM/yyyy, h:mm a'"></span>
                                                </td>
                                            </tr>
                                            <tr class="vertical-align-top">
                                                <th>
                                                    Patient Type
                                                </th>
                                                <td>
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="demographData[0].bookingType"></span>
                                                </td>
                                                <th>
                                                    Received Date
                                                </th>
                                                <td>
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="demographData[0].receivedDate | date:'dd/MM/yyyy, h:mm a'"></span>
                                                </td>
                                            </tr>
                                            <tr class="vertical-align-top">
                                                <th>
                                                    Consultant
                                                </th>
                                                <td class="d-flex">
                                                    <span class="marginright5">: </span>
                                                    <span class="text-capitalize" [textContent]="demographData[0].doctorName || '------'"></span>
                                                </td>
                                                <th>
                                                    Reported Date
                                                </th>
                                                <td>
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="demographData[0].technicianVerificationDate | date:'dd/MM/yyyy, h:mm a'"></span>
                                                </td>
                                            </tr>
                                            <tr class="vertical-align-top">
                                                <th>
                                                    Ref.Doctor
                                                </th>
                                                <td class="d-flex">
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="patient?.referredBy"></span>
                                                </td>
                                                <th>
                                                    Approved Date
                                                </th>
                                                <td>
                                                    <span class="marginright5">: </span>
                                                    <!--<span [textContent]="reportSignatures[reportSignatures.length-1].approvedDate | date:'dd/MM/yyyy, h:mm a'"></span>-->
                                                </td>
                                            </tr>
                                            <tr *ngIf="demographData[0].bookingType =='IP'" class="vertical-align-top">
                                                <th>
                                                    IP No
                                                </th>
                                                <td class="d-flex">
                                                    <span class="marginright5">: </span>
                                                    <span class="text-capitalize" [textContent]="demographData[0].admissionNo || '------'"></span>
                                                </td>
                                                <th>
                                                    Bed Number
                                                </th>
                                                <td>
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="demographData[0].bedNumber"></span>
                                                </td>
                                            </tr>
                                            <tr class="vertical-align-top">
                                                <th>
                                                    Sample Type
                                                </th>
                                                <td class="d-flex">
                                                    <span class="marginright5">: </span>
                                                    <span class="text-capitalize" [textContent]="demographData[0].typeName || '------'"></span>
                                                </td>

                                            </tr>
                                        </table>
                                    </div>
                                </div>
                                <div class=" intermbackgroundOfwaterMark" *ngIf="reportSignatures.length == 0">
                                    <h6 class=" intermtextOfwaterMark watermarkfontsize">Interim Report </h6>
                                </div>
                            </div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <!--*** CONTENT GOES HERE ***-->
                            <div class="page">
                                <div>
                                    <div id="dataContainer">
                                        <div class="col-12 p-0 border-bottom_1px">

                                            <ng-container *ngIf="reportsAll?.length >0">
                                                <div class="overflow-auto">
                                                    <table class="w-100">
                                                        <thead>
                                                            <tr>
                                                                <th colspan="4">
                                                                    <div class="d-flex justify-content-center">
                                                                        <h5 class="text-decoration" *ngIf="reportsAll[0]?.departmentName !='' && reportsAll[0]?.departmentName != null">{{reportsAll[0]?.departmentName}}</h5>
                                                                    </div>
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <div>
                                                                        <div>
                                                                            <div class="overflow-auto textOfEveryVw">
                                                                                <table width="100%">
                                                                                    <thead>
                                                                                        <tr class="vertical-align-top">
                                                                                            <th><b>Test Name</b></th>
                                                                                            <th><b>Result</b></th>
                                                                                            <th><b>Biological Reference Range</b></th>
                                                                                            <th><b>Unit</b></th>
                                                                                            <th><b>Method</b></th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody *ngFor="let item of reportsAll;index as tdol;" >
                                                                                        <ng-container *ngFor="let templateItem of item?.templates[0]?.templateDetailsOrder">
                                                                                            <ng-container *ngIf="templateItem.parameterName">
                                                                                                <tr *ngIf="(templateItem.type == 'Regular' && templateItem.observedValue != null)||(templateItem.type == 'Microbiology')">
                                                                                                    <td>
                                                                                                        <span [textContent]="templateItem.parameterName || '-----'" *ngIf="templateItem.type == 'Regular' && templateItem.observedValue != null"></span>
                                                                                                        <h5 class="mb-0 text-capitalize text-primary" [textContent]="templateItem.parameterName || '-----'" *ngIf="templateItem.type != 'Regular' && templateItem.antibioticsCount >0"></h5>
                                                                                                        <ng-container *ngIf="templateItem.type != 'Regular'  && templateItem.antibioticsCount >0">
                                                                                                            <div *ngFor="let antibiotic of templateItem.antibiotics;index as j;">
                                                                                                                <span [textContent]="antibiotic.antibioticName || '-----'" *ngIf="antibiotic.observedValue !=null"></span>
                                                                                                            </div>
                                                                                                        </ng-container>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <span [ngClass]="{'font-weight-bolder' : templateItem.isBold}" [textContent]="templateItem.observedValue || '-----'" *ngIf="templateItem.type == 'Regular' && templateItem.observedValue != null && !templateItem.isObservedValuePower "></span>&nbsp;
                                                                                                        <span class="pow" [ngClass]="{'font-weight-bolder' : templateItem.isBold}" *ngIf="templateItem.type == 'Regular' && templateItem.observedValue != null   && templateItem.isObservedValuePower ">{{templateItem.observedValueList[0]}} <sup>{{templateItem.observedValueList[1]}}</sup></span>&nbsp;

                                                                                                        <span [textContent]="templateItem.showText" *ngIf="templateItem.type == 'Regular' && templateItem.observedValue != null"></span>
                                                                                                        <ng-container *ngIf="templateItem.type != 'Regular' && templateItem.antibioticsCount >0">
                                                                                                            <div *ngFor="let antibiotic of templateItem.antibiotics;index as j;">
                                                                                                                <span [textContent]="antibiotic.observedValue || '-----'" *ngIf="antibiotic.observedValue !=null"></span>
                                                                                                            </div>
                                                                                                        </ng-container>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <span *ngIf="templateItem.referenceOutput === 'numeric' && (templateItem.text === null || templateItem.text === undefined) && templateItem.type == 'Regular' && templateItem.observedValue != null">
                                                                                                            {{templateItem.selected.minValue}}-{{templateItem.selected.maxValue}}
                                                                                                        </span>
                                                                                                        <span *ngIf="templateItem.text && templateItem.type == 'Regular' && templateItem.observedValue != null">
                                                                                                            <span [innerHTML]="templateItem.text"></span>
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <span *ngIf="templateItem.selected && templateItem.selected != undefined && templateItem.selected.unitName != undefined && templateItem.selected.unitName != null && templateItem.type == 'Regular' && templateItem.observedValue != null">
                                                                                                            {{templateItem.selected.unitName || ''}}
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td>
                                                                                                        <span [textContent]="templateItem.methodName || ' '" *ngIf="templateItem.type == 'Regular' && templateItem.observedValue != null"></span>
                                                                                                        <span [textContent]="templateItem.methodName || ' '" *ngIf="templateItem.type != 'Regular' && templateItem.antibioticsCount >0"></span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </ng-container>
                                                                                            <ng-container *ngIf="templateItem.componentName">
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <h5 *ngIf="templateItem.isShow" class="text-decoration">{{templateItem.componentName}}</h5>
                                                                                                    </td>
                                                                                                </tr>
                                                                                                <ng-container *ngFor="let parameter of templateItem.parameters;index as j;">
                                                                                                    <tr *ngIf="(parameter.type == 'Regular' && parameter.observedValue != null)||(parameter.type == 'Microbiology')">
                                                                                                        <td *ngIf="templateItem.parameters.length>0">
                                                                                                            <span [textContent]="parameter.parameterName || '-----'" *ngIf="parameter.type == 'Regular' && parameter.observedValue != null"></span>
                                                                                                            <h5 class="mb-0 text-capitalize text-primary" [textContent]="parameter.parameterName || '-----'" *ngIf="parameter.type != 'Regular' && parameter.antibiotics.length >0"></h5>
                                                                                                            <ng-container *ngIf="parameter.type != 'Regular' && parameter.antibiotics.length > 0">
                                                                                                                <div *ngFor="let antibiotic of parameter.antibiotics;index as j;">
                                                                                                                    <span [textContent]="antibiotic.antibioticName || '-----'" *ngIf="antibiotic.observedValue !=null"></span>
                                                                                                                </div>
                                                                                                            </ng-container>
                                                                                                        </td>
                                                                                                        <td *ngIf="templateItem.parameters.length>0">
                                                                                                            <span [ngClass]="{'font-weight-bolder' : parameter.isBold}" [textContent]="parameter.observedValue || '-----'" *ngIf="parameter.type == 'Regular' && parameter.observedValue != null && !parameter.isObservedValuePower "></span>&nbsp;
                                                                                                            <span class="pow" [ngClass]="{'font-weight-bolder' : parameter.isBold}" *ngIf="parameter.type == 'Regular' && parameter.observedValue != null && parameter.isObservedValuePower ">{{parameter.observedValueList[0]}} <sup>{{parameter.observedValueList[1]}}</sup></span>&nbsp;

                                                                                                            <span [textContent]="parameter.showText" *ngIf="parameter.type == 'Regular' && parameter.observedValue != null"></span>
                                                                                                            <ng-container *ngIf="parameter.type != 'Regular'  && parameter.antibiotics.length >0">
                                                                                                                <div *ngFor="let antibiotic of parameter.antibiotics;index as j;">
                                                                                                                    <span [textContent]="antibiotic.observedValue || '-----'" *ngIf="antibiotic.observedValue !=null"></span>
                                                                                                                </div>
                                                                                                            </ng-container>
                                                                                                        </td>
                                                                                                        <td *ngIf="templateItem.parameters.length>0">
                                                                                                            <span *ngIf="parameter.referenceOutput === 'numeric' && (parameter.text === null || parameter.text === undefined) && parameter.type == 'Regular' && parameter.observedValue != null">
                                                                                                                {{parameter.selected.minValue}}-{{parameter.selected.maxValue}}
                                                                                                            </span>
                                                                                                            <span *ngIf="parameter.text && parameter.type == 'Regular' && parameter.observedValue != null">
                                                                                                                <span [innerHTML]="parameter.text"></span>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                        <td *ngIf="templateItem.parameters.length>0">
                                                                                                            <span *ngIf="parameter.selected && parameter.selected != undefined && parameter.selected.unitName != undefined && parameter.selected.unitName != null && parameter.type == 'Regular' && parameter.observedValue != null">
                                                                                                                {{parameter.selected.unitName || ''}}
                                                                                                            </span>
                                                                                                        </td>
                                                                                                        <td *ngIf="templateItem.parameters.length>0">
                                                                                                            <span [textContent]="parameter.methodName || ' '" *ngIf="parameter.type == 'Regular' && parameter.observedValue != null"></span>
                                                                                                            <span [textContent]="parameter.methodName || ' '" *ngIf="parameter.type != 'Regular' && parameter.antibioticsCount >0"></span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-container>
                                                                                            </ng-container>
                                                                                        </ng-container>
                                                                                        
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <table>
                                                                        <tr *ngFor="let report of reportsAll">
                                                                            <td>
                                                                                <div class="w-100" *ngIf="report.templates[0].isMethod || report.templates[0].isInterpretation">
                                                                                    <div class="p-0">
                                                                                        <div class="d-flex flex-wrap">
                                                                                            <div class="col-12 p-0" *ngIf="report.templates[0].isMethod">
                                                                                                <div class="form-group">
                                                                                                    <h5 class="mb-0 headingText1 font-weight-bold">Method</h5>
                                                                                                    <div [innerHTML]="report.templates[0].methodText"></div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-12 p-0" *ngIf="report.templates[0].isInterpretation">
                                                                                                <div class="form-group">
                                                                                                    <label class="mb-0 headingText1 font-weight-bold">Interpretation/Result</label>
                                                                                                    <div [innerHTML]="report.templates[0].interpretationText"></div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>

                                                            </tr>
                                                        </tbody>
                                                    </table>


                                                </div>
                                            </ng-container>

                                        </div>

                                        <div class="col-12">
                                            <div class="d-flex justify-content-center">
                                                <p>--End Of Report--</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                            </div>

                        </td>
                    </tr>
                </tbody>

                <tfoot>
                    <tr class="d-none d-print-table-row">
                        <td>
                            <div class="col-12">
                                <div class="align-items-baseline d-flex flex-wrap justify-content-between mb-1 ml-lg-4 mr-lg-5">
                                    <div class="align-items-center d-flex gap20px">
                                        <div>
                                            <qrcode [qrdata]="QrCode" [width]="150" [errorCorrectionLevel]="'M'" [elementType]="'img'"></qrcode>
                                        </div>
                                    </div>
                                    <div class="text-capitalize" *ngFor="let signature of reportSignatures;index as p;">
                                        <div class="bg-light">
                                            <img [src]="safe(signature.signature)" alt="DoctorSignature" class="img-fluid height100px">
                                        </div>
                                        <h6 class="m-0 headingText1" [textContent]="signature.providerName"></h6>
                                        <h6 class="m-0 headingText1">{{signature.departmentName}}</h6>
                                        <h6 class="m-0 headingText1">{{signature.approvedDate | date:'dd/MM/yyyy, h:mm a'}}</h6>
                                    </div>
                                </div>
                            </div>
                            <!--place holder for the fixed-position footer-->
                            <div class="page-footer-space-pdetail"></div>
                        </td>
                    </tr>
                </tfoot>

            </table>

            <table class="w-100" [ngClass]="isPrintTermsAndConditions?'':'d-print-none'">
                <thead>
                    <tr class="d-none d-print-table-row">
                        <td>
                            <div class="text-center">
                                <div id="header-content" class="w-100">
                                    <div class="d-table-cell page-header-space mb-1 vertical-align-middle" *ngIf="isPrintLogo">
                                        <banner-setting [reportName]='"Lab Information Report Header"'></banner-setting>
                                    </div>
                                    <div *ngIf="!isPrintLogo" class="mt100">

                                    </div>
                                    <!--<div class="overflow-auto">
                                        <table class="text-left line-height16 w-100">
                                            <tr class="vertical-align-top">
                                                <th>
                                                    <h5 class="white-space-nowrap" *ngIf="demographData[0].patientName">Patient Name</h5>
                                                </th>
                                                <td class="d-flex">
                                                    <span class="marginright5">: </span>
                                                    <span class="text-capitalize" *ngIf="demographData[0].patientName" [textContent]="demographData[0].patientName"></span>
                                                </td>
                                                <th>
                                                    UMR No
                                                </th>
                                                <td class="white-space-nowrap">
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="demographData[0].umrNo || '-----'"></span>
                                                </td>
                                            </tr>
                                            <tr class="vertical-align-top">
                                                <th>
                                                    Age/Gender
                                                </th>
                                                <td>
                                                    <div class="d-flex">
                                                        <span class="marginright5">: </span>
                                                        <div>
                                                            <span *ngIf="!demographData[0].dateOfBirth">{{demographData[0].age}} Year &nbsp;0 Month&nbsp;0Days </span>
                                                            <span *ngIf="demographData[0].dateOfBirth">{{age.year}} Year &nbsp;{{age.month}} Month&nbsp;{{age.day}} Days </span>
                                                            <span *ngIf="demographData[0].gender">&nbsp;/</span>
                                                            <span *ngIf="demographData[0].gender" [textContent]="demographData[0].gender || '----'"></span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <th>
                                                    Invoice Date
                                                </th>
                                                <td class="white-space-nowrap">
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="demographData[0].bookedDate | date:'dd/MM/yyyy, h:mm a'"></span>
                                                </td>
                                            </tr>
                                            <tr class="vertical-align-top">
                                                <th>
                                                    {{relationType ? relationType : 'RelativeName' }}
                                                </th>
                                                <td>
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="patient?.relativeName"></span>
                                                </td>
                                                <th class="white-space-nowrap">
                                                    Ref.Doctor
                                                </th>
                                                <td>
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="patient?.referredBy"></span>
                                                </td>
                                            </tr>
                                            <tr class="vertical-align-top">
                                                <th>
                                                    Billed Date
                                                </th>
                                                <td>
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="billedDate | date:'dd/MM/yyyy, h:mm a'"></span>
                                                </td>
                                                <th class="white-space-nowrap">
                                                    Approved Date
                                                </th>
                                                <td>
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="reportSignatures[reportSignatures.length-1].approvedDate | date:'dd/MM/yyyy, h:mm a'"></span>
                                                </td>
                                            </tr>
                                            <tr class="vertical-align-top">
                                                <th>
                                                    Location
                                                </th>
                                                <td>
                                                    <span class="marginright5">: </span>
                                                    <span class="text-capitalize" [textContent]="demographData[0].fromLocation"></span>
                                                </td>
                                                <th class="white-space-nowrap">
                                                    Requisition No
                                                </th>
                                                <td class="white-space-nowrap">
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="demographData[0].requisitionNumber"></span>
                                                </td>
                                            </tr>
                                            <tr class="vertical-align-top">
                                                <th>
                                                    Patient Type
                                                </th>
                                                <td>
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="demographData[0].bookingType"></span>
                                                </td>
                                                <th class="white-space-nowrap">
                                                    Collected Date
                                                </th>
                                                <td>
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="demographData[0].collectionDate | date:'dd/MM/yyyy, h:mm a'"></span>
                                                </td>
                                            </tr>
                                            <tr class="vertical-align-top">
                                                <th>
                                                    Consultant
                                                </th>
                                                <td class="d-flex">
                                                    <span class="marginright5">: </span>
                                                    <span class="text-capitalize" [textContent]="demographData[0].doctorName || '------'"></span>
                                                </td>
                                                <th>
                                                    Received Date
                                                </th>
                                                <td>
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="demographData[0].receivedDate | date:'dd/MM/yyyy, h:mm a'"></span>
                                                </td>
                                            </tr>
                                            <tr class="vertical-align-top">
                                                <th>
                                                    Sample Type
                                                </th>
                                                <td class="d-flex">
                                                    <span class="marginright5">: </span>
                                                    <span class="text-capitalize" [textContent]="demographData[0].typeName || '------'"></span>F
                                                </td>
                                                <th>
                                                    Reported Date
                                                </th>
                                                <td>
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="demographData[0].technicianVerificationDate | date:'dd/MM/yyyy, h:mm a'"></span>
                                                </td>
                                            </tr>
                                            <tr *ngIf="demographData[0].bookingType =='IP'" class="vertical-align-top">
                                                <th>
                                                    IP No
                                                </th>
                                                <td class="d-flex">
                                                    <span class="marginright5">: </span>
                                                    <span class="text-capitalize" [textContent]="demographData[0].admissionNo || '------'"></span>
                                                </td>
                                                <th>
                                                    Bed Number
                                                </th>
                                                <td>
                                                    <span class="marginright5">: </span>
                                                    <span [textContent]="demographData[0].bedNumber"></span>
                                                </td>
                                            </tr>
                                        </table>
                                    </div>-->
                                </div>
                            </div>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>
                            <div class="mt-3">
                                <h4 class="border-bottom border-dark border-top topHeading font-weight-bold mb-2 p-1 text-center text-dark">
                                    TERMS AND CONDITIONS FOR LABORATORY TESTING AND REPORTING
                                </h4>
                                <div class="d-flex flex-lg-nowrap flex-wrap headingText1 pr-3 text-justify gap15px">
                                    <div>
                                        <ul>
                                            <li>
                                                The reported results are for information and interpretation of the
                                                referring clinician or other medical professional, who understands
                                                reporting units, reference ranges and limitation of technologies.
                                            </li>
                                            <li>
                                                A qualified medical professional should interpret the laboratory
                                                results and adjuvant information using objective clinical judgment
                                                and in conjunction with patient presentation, history, and other
                                                diagnostic evidence.
                                            </li>
                                            <li>
                                                The laboratory services at {{demographData[0].practiceName || '---'}}
                                                Hospital (A unit of {{demographData[0].practiceName || '---'}})
                                                may outsource certain tests to qualified labs.
                                            </li>
                                            <li>
                                                Test results depend upon quality of sample and testing method, and
                                                they may vary from lab-to-lab and/or from time-to-time for the same
                                                parameters for the same patient. To help maintain sample quality, the
                                                laboratory specifies criteria for specimen storage in accordance with
                                                accreditation guidelines and testing methods. For more information,
                                                please contact the Laboratory Services team.
                                            </li>
                                        </ul>
                                    </div>
                                    <div>
                                        <ul>
                                            <li>
                                                Specified biological reference ranges encompass 95% confidence
                                                limits of a given population, which implies test results for healthy
                                                individuals may fall in the abnormal range. In case of unexpectedly
                                                abnormal lab results, the lab services team may perform repeat
                                                analysis, based on Clinician/Client’s request and after due
                                                investigation.
                                            </li>
                                            <li>
                                                We assure that we maintain confidentiality of this information.
                                            </li>
                                            <li>
                                                {{demographData[0].practiceName || '---'}}, its directors, employees and/or
                                                representatives are not liable for claims of damages incurred by any
                                                person, including the patient as a result of interpretation of lab reports.
                                                Financial or monetary claims are subject to approval by the
                                                management of {{demographData[0].practiceName || '---'}} and shall not exceed the
                                                stipulated test cost under any circumstances.
                                            </li>
                                            <li>
                                                All claims are subject to the jurisdiction of {{demographData[0].city || '---'}} ({{demographData[0].state || '---'}}) India.
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td>
                            <div class="col-12">
                                <div class="align-items-baseline d-flex flex-wrap justify-content-between mb-1 ml-lg-4 mr-lg-5">
                                    <div class="align-items-center d-flex gap20px">
                                        <div>
                                            <qrcode [qrdata]="QrCode" [width]="150" [errorCorrectionLevel]="'M'" [elementType]="'img'"></qrcode>
                                        </div>

                                    </div>
                                    <div class="text-capitalize" *ngFor="let signature of reportSignatures;index as p;">
                                        <div class="bg-light">
                                            <img [src]="safe(signature.signature)" alt="DoctorSignature" class="img-fluid height100px">
                                        </div>
                                        <h6 class="m-0 headingText1" [textContent]="signature.providerName"></h6>
                                        <h6 class="m-0 headingText1">{{signature.departmentName}}</h6>
                                        <h6 class="m-0 headingText1">{{signature.approvedDate | date:'dd/MM/yyyy, h:mm a'}}</h6>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tfoot>
            </table>
            <div *ngIf="pdfLoading" class="bg-soft-secondary">
                <div class="d-flex align-items-center">
                    <pre class="spinner-grow text-warning mb-0" role="status" aria-hidden="true"></pre>
                    <pre class="ml-2 mb-0 font-14">Please wait while loading PDF details....</pre>
                </div>
            </div>
            <table class="w-100">
                <tbody>
                    <tr>
                        <td>
                            <div class="col-12 disable-on-pdf " *ngIf="reportData ">
                                <div class="heading1 pl-1 pr-1" *ngIf="!loading && checkExternal && checkExternal.reportUrl">
                                    <pdf-viewer [src]="reportData['changingThisBreaksApplicationSecurity']"
                                                [render-text]="true"
                                                [autoresize]="true"
                                                [original-size]="false"
                                                [fit-to-page]="false"
                                                (on-progress)="onProgress($event)"
                                                class="pdfstyle">
                                    </pdf-viewer>
                                </div>
                            </div>
                            <div *ngIf="!pdfLoading">

                                <div class="w-100 overflow-auto" *ngFor="let image of attachmentUrl; index as t">
                                    <img class="w-100" src="{{image.url}}" />
                                </div>
                            </div>
                            <div class="w-100 overflow-auto" *ngFor="let image of attachmentImageUrl; index as t">
                                <img class="w-100" src="{{image.url}}" />
                            </div>
                            <div class="w-100 overflow-auto" *ngFor="let video of attachmentVideoUrl; index as t">
                                <video width="320" height="240" controls>
                                    <source src="{{video.url}}" type="video/mp4">
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tfoot>
                    <tr>
                        <td>
                            <div class="page-footer-space-pdetail"></div>
                        </td>
                    </tr>
                </tfoot>

            </table>
        </div>

    </div>

</div>


