import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewEncapsulation } from "@angular/core";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { ApiResources } from "@shared/helpers";
import { Counselling, CounsellingPackage, IResource, IUserAccount, Page } from "@shared/models";
import { AppData, HttpService, NotifyService, ResourceService } from "@shared/services";
import { AdmitType, CounsellingViewType, CounsellingWithPackages } from "@src/app/shared/enums";
import { finalize, takeUntil } from "rxjs/operators";
import moment from 'moment';

@Component({
    selector: "view-counselling",
    templateUrl: "./view-counselling.html",
    encapsulation: ViewEncapsulation.None
})
export class ViewCounsellingComponent implements OnInit {
    @Input() counsellingId: number;
    @Output() close: EventEmitter<void> = new EventEmitter();
    @Output() added: EventEmitter<boolean> = new EventEmitter();

    @Input() counsellingViewType: CounsellingViewType = CounsellingViewType.ViewMode;
    @Input() admitType: AdmitType = AdmitType.All;
    @Input() counsellingPackageIds: Array<number> = [];
    @Output() addedPackages: EventEmitter<CounsellingWithPackages> = new EventEmitter();
    @Input() isPrintLogo: boolean;
    @Input() isFooter: boolean;

    admitTypeEnum = AdmitType;
    viewType = CounsellingViewType;
    page: Page;
    loading: boolean;
    counselling: Counselling;
    counsellingPackages: Array<CounsellingPackage>;
    isSubmitting: boolean;
    signatureType: string;
    signatureFor: string;
    isSaveSignature: boolean;
    counsellorBase64Signature: string;
    patientBase64Signature: string;
    selectedCounsellingPackage: CounsellingPackage;

    private modalRef: NgbModalRef;
    gpla: any;
    eddDate: any;
    husbandName: string;
    providerName: string;
    extraExclusionsNotes: string;
    encounterType: string;
    record: Array<IResource>;
    ancNumber: string;
    constructor(
        private readonly appData: AppData,
        private readonly modalService: NgbModal,
        private readonly httpService: HttpService,
        private readonly notifyService: NotifyService,
        private readonly resourceService: ResourceService,
    ) {
        this.page = new Page();
    }

    ngOnInit(): void {
        this.setUserAccount();
        this.findCounselling();
    }

    onSignatureDone(signature: string) {
        this.isSaveSignature = true;
        this.patchSignature(signature);
        this.isSaveSignature = false;
        this.onCloseModal();
    }

    onOpenModel(content: TemplateRef<any>, signatureFor: string) {
        this.signatureType = "signature";
        this.signatureFor = signatureFor;
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "xl",
            windowClass: "custom-modal effect-scale"
        });
    }

    onReceiveDrewSignature(signature: string) {
        this.patchSignature(signature);
    }

    onSaveUploadedSignature(blob: Blob) {
        this.isSaveSignature = true;
        var reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
            const signature = reader.result as string;
            this.patchSignature(signature);
            this.isSaveSignature = false;
            this.onCloseModal();
        }
    }
    private fetchgetAncNumber(patientId) {
        this.resourceService.getAncNumber(patientId)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                if (response && response.length > 0) {
                    this.record = response
                    this.ancNumber = response[0].value;
                    this.encounterType = response[0].optionalText;
                }
            });
    }


    private fetchPatientBMI(patientId) {
        this.resourceService.getPatientBMI(patientId)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                if (response && response.length > 0) {
                    this.gpla = response[0] ? JSON.parse(response[0].optionalText) : null;
                    if (this.gpla && this.gpla.edd) {
                        this.eddDate = moment(this.gpla.edd, 'DD/MM/YYYY');
                    }
                }
            });
    }
    trackByCounsellingPackage(_: number, item: CounsellingPackage): number {
        return item.counsellingPackageId;
    }

    onViewCounsellingPackage(counsellingPackage: CounsellingPackage, content: TemplateRef<any>): void {
        this.selectedCounsellingPackage = counsellingPackage;
        this.modalRef = this.modalService.open(content, {
            backdrop: "static",
            keyboard: false,
            centered: true,
            size: "lg",
            windowClass: "custom-modal effect-scale"
        });
    }

    onCloseModal() {
        try {
            this.modalRef.close();
            this.modalRef = undefined;
        } catch (e) {
            // ignored;
        }

        this.signatureType = undefined;
        this.signatureType = undefined;
        this.isSaveSignature = undefined;
    }

    onSubmit(): void {
        if (!((this.counselling.patientSignature && this.counselling.counsellorSignature) || (this.patientBase64Signature && this.counsellorBase64Signature))) {
            this.notifyService.warning("The detailed description of the counselling must be signed by the patient and the counselor in order to be published.");
            return;
        }

        this.isSubmitting = true;
        this.httpService
            .post(ApiResources.getURI(ApiResources.counselling.base, ApiResources.counselling.publish), {
                counsellingId: this.counselling.counsellingId,
                createdBy: this.page.userAccount.accountId,
                patientBase64Signature: this.patientBase64Signature,
                counsellorBase64Signature: this.counsellorBase64Signature,
                patientId: this.counselling.patientId,
                umrNo: this.counselling.umrNo,
                createdByName: this.page.userAccount.fullName,
                createdByRoleId: this.page.userAccount.roleId,
                locationId: this.page.userAccount.locationId
            })
            .pipe(finalize(() => { this.isSubmitting = false }), takeUntil(this.page.unSubscribe))
            .subscribe({
                next: () => {
                    this.notifyService.success("Counselling has been published successfully.");
                    this.added.emit(true);
                    this.close.emit();
                },
                error: () => this.notifyService.defaultError()
            });
    }

    onCancel(): void {
        this.close.emit();
    }

    /* Using Same component for packages add into admission
        While on Add admission, and in the Packages and Services page
        User Selects one or more packages then clicks on submit
        Those selected packages will be added into Admission Package table */

    togglePackage = (item: CounsellingPackage): void => {
        const found = this.counsellingPackages.find(x => x.counsellingPackageId === item.counsellingPackageId);
        if (found) {
            found.isAdded = !found.isAdded
        }

        if (this.admitType !== AdmitType.Appointment) {
            const selectedPackages = this.counsellingPackages.filter(x => x.isAdded);
            if (selectedPackages && selectedPackages.length >= 1) {
                this.counsellingPackages.forEach(x => {
                    x.strikeItem = x.chargeCategoryName !== selectedPackages[0].chargeCategoryName
                });
            } else {
                this.counsellingPackages.forEach(x => { x.strikeItem = false });
            }
        }
    }

    onPackagesAdded = () => {
        const selected = this.counsellingPackages.filter(x => x.isAdded);
        if (selected.length) {
            this.addedPackages.emit({ counselling: this.counselling, packages: selected } as CounsellingWithPackages);
        } else {
            this.notifyService.warning("Please select at least one package");
        }
    }

    ngOnDestroy(): void {
        this.page.unsubscribeAll();
    }

    private patchSignature(signature: string): void {
        if (this.signatureFor === "Counselling Given To")
            this.patientBase64Signature = signature;
        else
            this.counsellorBase64Signature = signature;
    }

    private setUserAccount(): void {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe({
                next: (userAccount: IUserAccount) => this.page.userAccount = userAccount,
                error: () => this.page.userAccount = undefined
            });
    }
    private fetchCounsellingProvider(patientId) {
        this.resourceService.getCounsellingProvider(patientId)
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((response: Array<IResource>) => {
                if (response && response.length > 0) {
                    this.providerName = response[0] ? response[0].optionalText : null;

                }
            });
    }

    private findCounselling(): void {
        this.loading = true;
        this.httpService
            .get(ApiResources.getURI(ApiResources.counselling.base, ApiResources.counselling.find), { counsellingId: this.counsellingId })
            .pipe(finalize(() => this.loading = false), takeUntil(this.page.unSubscribe))
            .subscribe({
                next: (response: { counselling: Counselling, counsellingPackages: Array<CounsellingPackage> }) => {
                    this.counselling = response.counselling;
                    this.fetchPatientBMI(this.counselling.patientId);
                    this.fetchCounsellingProvider(this.counselling.patientId);
                    this.fetchgetAncNumber(this.counselling.patientId);
                    let counsellingPackages = response.counsellingPackages;
                    if (this.counselling.relation == "Husband") {
                        this.husbandName = this.counselling.relativeName;
                    }
                    if (this.counsellingPackageIds && this.counsellingPackageIds.length && this.counsellingViewType === CounsellingViewType.ReadOnlyMode) {
                        counsellingPackages = counsellingPackages.filter(x => this.counsellingPackageIds.includes(x.counsellingPackageId));
                    }
                    if (this.admitType === AdmitType.Appointment) {
                        counsellingPackages.forEach(x => {
                            x.strikeItem = x.chargeCategoryName !== 'GENERAL WARD'
                        })
                    }
                    this.counsellingPackages = counsellingPackages;
                    this.counsellingPackages.forEach(m => m.chargeCategory = { id: m.chargeCategoryId, name: m.chargeCategoryName } as IResource)
                }
            });
    }
    
}