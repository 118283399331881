/* eslint-disable @angular-eslint/no-output-on-prefix */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { Component, OnInit, EventEmitter, Input, OnDestroy, Output, ViewEncapsulation } from "@angular/core";
import { Page, IUserAccount, ProgressData } from "../../models";
import { AppData, HttpService, PrintOptionService } from "../../services";
import { takeUntil, finalize } from "rxjs/operators";
import { ApiResources, UtilHelper, LinqHelper } from "../../helpers";
import { forkJoin } from "rxjs";
import { LabTransferModel, LabParameterInputModel, LabTemplateHeaderForInput, LabParameterInputHeaderModel, LabComponentHeaderForInput, LabBillDetailModel, LabBillHeaderModel, LabParameterGroup, LabTemplateDetail } from "../../../areas/admin/labs/pages/models";
import { LabReportSignature } from "../../../areas/admin/labs/pages/models/lab-report-signature";
import moment from "moment";
import { DomSanitizer } from "@angular/platform-browser";
import { Appointment, Patient, Setting } from "../../entities";
import { LabDocumentsDetails } from "../../entities/laboratory/lab-document-details.entity";
import { Pagination } from "@shared/models";
import { HostListener } from '@angular/core';
import html2canvas from 'html2canvas';
import { pow } from "mathjs";
class Age {
    year: number;
    month: number;
    day: number;
}

@Component({
    templateUrl: "./new-lab-report.html",
    selector: "new-lab-report",
    styleUrls: ["./new-lab-report.css"],
    encapsulation: ViewEncapsulation.None
})

export class NewLabReportWidget implements OnInit, OnDestroy {
    @Input() newLabBookingDetailId: number;
    @Input() encryptedNewLabBookingDetailId: string;
    @Output() onClose = new EventEmitter<any>();
    @Input() isPrintLogo: boolean;
    @Input() isFooter: boolean;
    isPrintTermsAndConditions: boolean;
    page: Page;
    loading: boolean;
    demographData: Array<LabTransferModel>;
    age: Age;
    parameters: LabParameterInputModel;
    reportSignatures: Array<LabReportSignature>;
    reports: LabParameterInputModel;
    checkExternal: LabBillDetailModel;
    isExternalLoading: boolean;
    isExternalReport: boolean;
    reportData: any;
    reportDataWithoutAuth: any;
    QrCode: string = null;
    printIframe: any;
    blobUrls: any;
    headerUrl: string;
    footerUrl: string;
    nablUrl: string;
    careAxesUrl: string = "assets/images/careaxesLogo.png";
    documents: Array<LabDocumentsDetails>;
    encryptedPatientId: string;
    pagination: Pagination;
    allDocumentUrl: string[];
    allDocimentId: number[];
    reportDataArray: any;
    reportDataWithoutAuthArray: any
    document: LabDocumentsDetails;
    loadingDocument: boolean;
    isFirstCall: boolean;
    isAdmission: boolean;
    appointment: Appointment;
    relationType: string;
    billedDate: Date;
    bill: LabBillHeaderModel;
    logoDocument: any;
    attachmentUrl: any = [];
    attachmentImageUrl: any = [];
    attachmentVideoUrl: any = [];
    pdfLoading: boolean;
    patient: Patient;
    @Output() isExternal = new EventEmitter<boolean>();
    @Output() uplodpfd = new EventEmitter<any>();
    templateDetail: LabTemplateDetail[];
    templateDetailsOrder: Array<any>;

    constructor(
        private readonly appData: AppData,
        private readonly httpService: HttpService,
        private readonly sanitizer: DomSanitizer,
        private readonly printOptionService: PrintOptionService
    ) {
        this.page = new Page();
        this.age = new Age();
        this.checkExternal = new LabBillDetailModel();
        this.demographData = new Array<LabTransferModel>();

        this.initPagination();
        this.isFirstCall = true;
        this.templateDetailsOrder = new Array<any>();

    }

    @HostListener('window:keydown', ['$event'])
    handleKeyDown(event: KeyboardEvent) {
        if (event.ctrlKey && event.key === 'p') {
            event.preventDefault();
            this.generatePDF();
        }
    }

    generatePdfFromButton() {
        this.isFirstCall = false;
        this.generatePDF();
    }
    async generatePDF() {
        let elementData = [];
        const headerElement = document.getElementById('header-content');
        const headerCanvas = await html2canvas(headerElement, {
            scale: 1,
            useCORS: true
        });
        const headerImageDataUrl = headerCanvas.toDataURL('image/png');
        const headerImageBytes = await fetch(headerImageDataUrl).then(res => res.arrayBuffer());

        try {

            const elementsToDisable = Array.from(document.querySelectorAll('.disable-on-pdf'));
            elementsToDisable.forEach((el) => {
                elementData.push({
                    element: el,
                    parent: el.parentElement,
                    nextSibling: el.nextSibling
                });
                el.remove();
            });

            const htmlContent = document.getElementById('invoiceId')?.outerHTML;
            if (!htmlContent) {
                throw new Error('HTML content is missing');
            }

            const html2pdf = await import('html2pdf.js');
            const { PDFDocument } = await import('pdf-lib');


            const pdfDoc = await html2pdf.default().from(htmlContent).set({
                filename: 'merged-content.pdf',
                margin: [200, 0, 10, 0],
                image: { type: 'png', quality: 1 },
                pagebreak: { mode: ['avoid-all'] },
                html2canvas: { scale: 1, useCORS: true },
                jsPDF: { unit: 'pt', format: 'a4', orientation: 'portrait', fontSize: 1 }
            }).output('blob').then(async (pdfBlob) => {
                const pdfArrayBuffer = await pdfBlob.arrayBuffer();
                return PDFDocument.load(pdfArrayBuffer);
            });


            const headerImage = await pdfDoc.embedPng(headerImageBytes);


            const pages = pdfDoc.getPages();
            for (let i = 0; i < pages.length; i++) {
                const page = pages[i];
                const { width, height } = page.getSize();
                const headerScale = width / headerCanvas.width;
                const headerWidth = width;
                const headerHeight = headerCanvas.height * headerScale;

                const yPosition = height - headerHeight;


                page.drawImage(headerImage, {
                    x: 0,
                    y: yPosition,
                    width: headerWidth,
                    height: headerHeight
                });
            }


            if (this.reportDataWithoutAuthArray && this.reportDataWithoutAuthArray.length > 0) {
                for (const item of this.reportDataWithoutAuthArray) {
                    const base64String = item.url.split(',')[1];
                    const binaryString = atob(base64String);
                    const uint8Array = new Uint8Array(binaryString.length);
                    for (let j = 0; j < binaryString.length; j++) {
                        uint8Array[j] = binaryString.charCodeAt(j);
                    }
                    const arrayBuffer = uint8Array.buffer;

                    if (item.filetype === 'pdf') {
                        const pdfDocToMerge = await PDFDocument.load(arrayBuffer);
                        const pagesToMerge = await pdfDoc.copyPages(pdfDocToMerge, pdfDocToMerge.getPageIndices());
                        pagesToMerge.forEach(page => pdfDoc.addPage(page));
                    } else if (['png', 'jpg', 'jpeg'].includes(item.filetype)) {
                        const imagePdfDoc = await PDFDocument.create();
                        const image = await imagePdfDoc.embedJpg(arrayBuffer);
                        const page = imagePdfDoc.addPage([image.width, image.height]);
                        page.drawImage(image, { x: 0, y: 0, width: image.width, height: image.height });
                        const pagesToMerge = await pdfDoc.copyPages(imagePdfDoc, imagePdfDoc.getPageIndices());
                        pagesToMerge.forEach(page => pdfDoc.addPage(page));
                    } else {
                        console.error(`Unsupported file type: ${item.filetype}`);
                    }
                }
            }

            const pdfBytes = await pdfDoc.save();
            const blob = new Blob([pdfBytes], { type: 'application/pdf' });
            elementData.forEach(({ element, parent, nextSibling }) => {
                if (nextSibling) {
                    parent.insertBefore(element, nextSibling);
                } else {
                    parent.appendChild(element);
                }
            });

            if (!this.isFirstCall) {
                this.printPDF(blob);
            }
        } catch (error) {
            console.error(error);
            elementData.forEach(({ element, parent, nextSibling }) => {
                if (nextSibling) {
                    parent.insertBefore(element, nextSibling);
                } else {
                    parent.appendChild(element);
                }
            });
        }
    }

    private printPDF(blob: Blob) {
        const url = URL.createObjectURL(blob);
        const newTab = window.open(url, '_blank', 'fullscreen=yes');

        newTab.onload = () => {
            newTab.print();
        };
    }

    ngOnInit() {
        this.appData.userAccount
            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe((userAccount: IUserAccount) => {
                if (userAccount) {
                    this.page.userAccount = userAccount;
                    this.printOptionService.get((is) => { this.isPrintLogo = is; });
                    this.getLabReportImage('Header', 'LabReportHeader');
                    this.getLabReportImage('Footer', 'LabReportFooter');
                    this.fetchCommonSettings();
                    this.fetLabReport();
                    this.generatePDF();
                    this.checkMultiplExternalReport();
                } else {
                    this.page.userAccount = undefined;
                    this.printOptionService.get((is) => { this.isPrintLogo = is; });
                    this.getLabReportImage('Header', 'LabReportHeader');
                    this.getLabReportImage('Footer', 'LabReportFooter');
                    this.fetLabReport();
                }
            });
    }
    private initPagination() {
        this.pagination = new Pagination();
        this.pagination.pageIndex = 1;
        this.pagination.pageSize = 12;
    }
    ngOnDestroy() {
        function cleanUp() {
            try {
                let doc = document.getElementById("myDOC");
                doc.remove();
            } catch (e) {
                console.log(e);
                console.clear();
            }
        }
        cleanUp();
        this.page.unsubscribeAll();
    }

    onCloseModal() {
        this.onClose.emit();
    }

    safe = (url: any) => {
        if (url) {
            return `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${url}`
        }
    }

    onProgress(progressData: ProgressData) {
        this.isExternalLoading = true;
        if (progressData.loaded === progressData.total) {
            this.isExternalLoading = false;
        }
    }



    onPrintPdf() {
        let printIframe;
        let blobUrls = [];
        let data = this.reportDataWithoutAuth;

        async function printMethod() {
            const resp = await fetch(data);
            const blob = await resp.blob();
            const url = URL.createObjectURL(blob);
            blobUrls.push(url);
            if (!printIframe) {
                printIframe = document.createElement('iframe');
                printIframe.setAttribute("id", "myDOC");
                document.body.appendChild(printIframe);

                printIframe.style.position = 'absolute';
                printIframe.style.top = '0';
                printIframe.style.left = '-1000px';

                printIframe.onload = () => {
                    setTimeout(() => {
                        if (printIframe) {
                            printIframe.focus();
                            printIframe.contentWindow.print();
                        }
                    }, 100)
                }
            }
            printIframe.src = url;
        }

        printMethod();
    }

    private fetLabReport() {
        let intervalHit = setInterval(() => {
            let setting = localStorage.getItem("settings");
            if (setting) {
                clearInterval(intervalHit);
                const request = {
                    newLabBookingDetailId: this.newLabBookingDetailId,
                    encryptedNewLabBookingDetailId: this.encryptedNewLabBookingDetailId,
                    fromTech: "tech"
                };
                this.loading = true;
                const demographApi = this.httpService.post(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.fetchDemographData), request);
                const inputParameters = this.httpService.post(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.labParameterForInput), request);
                const doctorSignature = this.httpService.get(ApiResources.getURI(ApiResources.labs.base, ApiResources.labs.fetchDoctorSignature), { encryptedNewLabBookingDetailId: this.encryptedNewLabBookingDetailId, newLabBookingDetailId: this.newLabBookingDetailId });
                const checkExternal = this.httpService.post(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.fetchLabBookingDetail), request);
                const detals = this.httpService.post(ApiResources.getURI(ApiResources.labs.base, ApiResources.labs.fetchCriticalPatient), request);
                const logos = this.httpService.get(ApiResources.getURI(ApiResources.labTransaction.base, ApiResources.labTransaction.getlocationlogos), { encryptedNewLabBookingDetailId: this.encryptedNewLabBookingDetailId, newLabBookingDetailId: this.newLabBookingDetailId });



                forkJoin([demographApi, inputParameters, doctorSignature, checkExternal, detals, logos])
                    .pipe(takeUntil(this.page.unSubscribe))
                    .pipe(finalize(() => this.loading = false))
                    .subscribe((response: Array<any>) => {
                        if (response[5]) {
                            this.prepareDocument(response[5]);
                        }
                        this.demographData = new Array<LabTransferModel>();
                        this.parameters = new LabParameterInputModel();
                        this.reportSignatures = new Array<LabReportSignature>();
                        this.fetchDocuments(response[0][0].newLabBookingDetailId);
                        if (response[3]) {
                            this.billedDate = response[3].newLabBookingHeaderCreatedDate;
                            this.checkExternal = response[3] as LabBillDetailModel;
                            if (this.checkExternal.transferredToExternal) {
                                this.isExternalReport = true;
                            }
                            else {
                                this.isExternalReport = false;
                            }
                            this.isExternal.emit(this.isExternalReport);

                        }
                        if (response[0] && this.isExternalReport == false) {
                            this.demographData = response[0] as Array<LabTransferModel>;
                            this.fetchPatient(this.demographData[0].encryptedId);
                            if (this.demographData && this.demographData.length > 0 && UtilHelper.isEmpty(this.demographData[0].dateOfBirth)) {
                                let mdob = moment(this.demographData[0].dateOfBirth, "YYYY-MM-DD");
                                let age = moment().diff(mdob, "months");
                                let yrs = (age / 12).toFixed(1);
                                let months = age % 12;
                                let days = moment().diff(mdob.add(yrs, 'years'), 'days', false);
                                this.age.day = days;
                                this.age.year = parseInt(yrs, 10);
                                this.age.month = months;
                                let date1 = moment(this.demographData[0].dateOfBirth, "YYYY-MM-DD");
                                let monthsa = moment().diff(date1, 'months');
                                date1.add(monthsa, 'months');
                                let daysa = moment().diff(date1, 'days');
                                this.age.day = daysa;
                            } else {
                                this.age = new Age();
                            } if (this.demographData[0].nablRequired && this.demographData[0].isNablRequired) {
                                this.getLabReportImage('Nabl', 'LabReportNabl');
                            }
                            this.QrCode = `${location.origin}${location.pathname}#/new-lab-reports/${this.newLabBookingDetailId}`;
                        }
                        if (response[1]) {
                            this.parameters = response[1] as LabParameterInputModel;
                            this.reports = new LabParameterInputModel();
                            this.reports = UtilHelper.clone(this.parameters);
                            this.reports.templates = new Array<LabTemplateHeaderForInput>();
                            this.parameters.templates.forEach((template: LabTemplateHeaderForInput) => {
                                let parameterList = new Array<LabParameterInputHeaderModel>();
                                template.components.forEach((component: LabComponentHeaderForInput) => {
                                    component.isShow = false;
                                    component.parameters.forEach((param: LabParameterInputHeaderModel) => {
                                        var parameterValue = 1;
                                        let observedValue;
                                        if (param.observedValue) {
                                            component.isShow = true;
                                        }
                                        if (param.observedValue  && (param.observedValue[0] == ">" || param.observedValue[0] == "<") && param.observedValue?.length > 1) {
                                            component.isShow = true;
                                            var value = param.observedValue[0];
                                            observedValue = +param.observedValue.substring(1)
                                            if (value == ">") {
                                                observedValue = observedValue + 1;
                                            } else if (value == "<") {
                                                observedValue = observedValue - 1;
                                            }
                                        } else {
                                            if (param.observedValue?.includes("^")) {
                                                var values = param.observedValue?.split('^');
                                                if (values?.length > 1) {
                                                    param.observedValueList = new Array<string>;
                                                    param.isObservedValuePower = true;
                                                    param.observedValueList.push(values[0]);
                                                    param.observedValueList.push(values[1]);
                                                    parameterValue = Number(pow(Number(values[0]), Number(values[1])));
                                                }
                                            }
                                            observedValue = +param.observedValue;
                                        }
                                        let range = param.selected;
                                        if (range != null) {
                                            let exactRange = range;
                                            if (exactRange.minValue || exactRange.maxValue) {
                                                if (exactRange.minValue > observedValue || (parameterValue == 1 ? false : exactRange.minValue > parameterValue)) {
                                                    param.showText = "L";
                                                    param.isBold = true;
                                                }
                                                else if (observedValue > +exactRange.maxValue || (parameterValue == 1 ? false : parameterValue > +exactRange.maxValue)) {
                                                    param.showText = "H";
                                                    param.isBold = true;
                                                }
                                            }
                                        }
                                        if (param.type == "Microbiology" && param.antibiotics.length > 0) {
                                            param.antibioticsCount = param.antibiotics.filter(item => item.observedValue != null).length;
                                        }
                                        parameterList.push(param);
                                    });
                                });
                                template.parameters.forEach((param: LabParameterInputHeaderModel) => {
                                   var parameterValue = 1;
                                    let observedValue;
                                    if (param.observedValue && (param.observedValue[0] == ">" || param.observedValue[0] == "<") && param.observedValue?.length > 1) {
                                        var value = param.observedValue[0];
                                        observedValue = +param.observedValue.substring(1)
                                        if (value == ">") {
                                            observedValue = observedValue + 1;
                                        } else if (value == "<") {
                                            observedValue = observedValue - 1;
                                        }
                                    } else {
                                        if (param.observedValue?.includes("^")) {
                                            var values = param.observedValue?.split('^');
                                            if (values?.length > 1) {
                                                param.observedValueList = new Array<string>;
                                                param.isObservedValuePower = true;
                                                param.observedValueList.push(values[0]);
                                                param.observedValueList.push(values[1]);
                                                parameterValue = Number(pow(Number(values[0]), Number(values[1])));
                                            }
                                        }
                                        observedValue = +param.observedValue;
                                    }
                                    let range = param.selected;
                                    if (range != null) {
                                        let exactRange = range;
                                        if (exactRange.minValue || exactRange.maxValue) {
                                            if (exactRange.minValue > observedValue || (parameterValue == 1 ? false : exactRange.minValue > parameterValue)) {
                                                param.showText = "L";
                                                param.isBold = true;
                                            }
                                            else if (observedValue > +exactRange.maxValue || (parameterValue == 1 ? false : parameterValue > +exactRange.maxValue)) {
                                                param.showText = "H";
                                                param.isBold = true;
                                            }
                                        }
                                    }
                                    if (param.type == "Microbiology" && param.antibiotics.length > 0) {
                                        param.antibioticsCount = param.antibiotics.filter(item => item.observedValue != null).length;
                                    }
                                    parameterList.push(param);
                                });
                                //let templateToAssign = UtilHelper.clone(template) as LabTemplateHeaderForInput;

                                //templateToAssign.components = new Array<LabComponentHeaderForInput>();
                                //templateToAssign.parameters = new Array<LabParameterInputHeaderModel>();
                                //LinqHelper.sort(parameterList, "displayOrder");
                                //templateToAssign.parameters = parameterList;
                                this.reports.templates.push(template);
                            });
                            this.fetchTemplateDetail(response[1]?.templates[0]?.labTemplateHeaderId)

                        }
                        if (response[2]) {
                            this.reportSignatures = response[2] as Array<LabReportSignature>;
                        }
                    }, () => {
                        //this.isNotFound = true;
                    });
            }
        }, 10);
    }

    private getLabReportImage(position: string, type: string) {

        this.httpService
            .get<Array<Setting>>(ApiResources.getURI(ApiResources.setting.base, ApiResources.setting.fetch), { type: type, active: true }, true)
            .subscribe(
                (response: Array<Setting>) => {
                    if (response && response.length > 0) {
                        if (UtilHelper.isEmpty(response[0].imageUrl)) {
                            response[0].imageUrl = `${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${response[0].imageUrl}`;
                            if (position == 'Header') {
                                this.headerUrl = response[0].imageUrl;
                            }
                            else if (position == 'Footer') {
                                this.footerUrl = response[0].imageUrl;
                            }
                            else if (position == 'Nabl') {
                                this.nablUrl = response[0].imageUrl;
                            }
                        }
                    }
                },
                () => {
                    this.headerUrl = null;
                    this.footerUrl = null;
                    this.nablUrl = null;
                }
            );
    }
    private fetchDocuments(id: number) {
        this.loading = true;
        const request = Object.assign(UtilHelper.clone(this.pagination));
        request["newLabBookingDetailId"] = id;
        request.pageIndex = -1;
        request["encryptedPatientId"] = this.encryptedPatientId ? this.encryptedPatientId : null;
        this.httpService.post(ApiResources.getURI(ApiResources.labDocumentDetails.base, ApiResources.labDocumentDetails.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { this.loading = false }))
            .subscribe((response: Array<LabDocumentsDetails>) => {
                this.documents = response;
                var documentUrl = this.documents.map(x => x.documentUrl);
                this.allDocumentUrl = documentUrl;
                var documentId = this.documents.map(x => x.documentDetailsId)
                this.allDocimentId = documentId;
                this.checkMultiplExternalReport();
                this.checkExternalReport();
            });
    }
    private checkMultiplExternalReport() {
        if (UtilHelper.isEmpty(this.allDocumentUrl)) {
            this.loading = true;
            this.httpService
                .post(ApiResources.getURI(ApiResources.labDocumentDetails.base, ApiResources.labDocumentDetails.downloadAllFile), { allDocumentUrl: this.allDocumentUrl, allDocimentId: this.allDocimentId })
                .pipe(takeUntil(this.page.unSubscribe))
                .subscribe(
                    (response: any) => {
                        this.reportDataArray = response.map(item => {
                            return this.sanitizer.bypassSecurityTrustResourceUrl(`data:application/pdf;base64, ${item.base64}`);
                        });
                        this.reportDataWithoutAuthArray = response.map((item, index) => {
                            try {
                                var content = item.base64;
                                const extension = this.allDocumentUrl[index].split('.').pop()?.toLowerCase();

                                if (extension === 'pdf') {
                                    this.pdfLoading = true;
                                    this.fetchLabReportPdfAttachment(content);
                                }
                                if (['jpg', 'jpeg', 'png', 'gif'].includes(extension)) {
                                    this.attachmentImageUrl.push({
                                        url: `data:image/png;base64,${content}`
                                    })
                                }
                                if (extension === 'mp4') {
                                    this.attachmentVideoUrl.push({
                                        url: `data:video/mp4;base64,${content}`
                                    })
                                }

                                return {
                                    filetype: this.getFileType(this.allDocumentUrl[index]),
                                    url: `data:application/pdf;base64,${item.base64}`
                                };
                            } catch (error) {
                                return null;
                            }

                        });
                        this.loading = false;
                        this.isExternalLoading = true;

                    }

                );

        }


    }
    fetchLabReportPdfAttachment(content: any) {
        this.httpService
            .post(ApiResources.getURI(ApiResources.labs.base, ApiResources.labs.pdf2imgFetch), { "url": content, "isBase64": true })

            .pipe(takeUntil(this.page.unSubscribe))
            .subscribe(
                (response: any) => {
                    response.forEach((item) => {
                        this.attachmentUrl.push({
                            url: `data:image/png;base64,${item}`
                        })

                    });
                    this.pdfLoading = false;
                }
            );

    }
    getFileType(url: string): string {
        const extension = url.split('.').pop()?.toLowerCase();
        if (extension === 'pdf') {
            return 'pdf';
        } else if (['png', 'jpg', 'jpeg', 'gif'].includes(extension)) {
            return extension;
        }
        else if (extension === 'mp4') {
            return extension;
        }
        return 'unknown';
    }
    private checkExternalReport() {
        if (this.checkExternal && UtilHelper.isEmpty(this.checkExternal.reportUrl)) {
            this.loading = true;
            this.httpService
                .post(ApiResources.getURI(ApiResources.documents.base, ApiResources.documents.downloadFile), { documentUrl: this.checkExternal.reportUrl })
                .pipe(takeUntil(this.page.unSubscribe))
                .subscribe(
                    (response: any) => {
                        this.reportData = this.sanitizer.bypassSecurityTrustResourceUrl(`data:application/pdf;base64, ${response.base64}`);
                        this.reportDataWithoutAuth = `data:application/pdf;base64, ${response.base64}`;
                        this.loading = false;
                        this.isExternalLoading = true;
                        var data = {};
                        data["reportData"] = this.reportData,
                            data["reportDataWithoutAuth"] = this.reportDataWithoutAuth
                        this.uplodpfd.emit(data)

                    }
                );
        }
    }
    private fetchPatient(id: string) {
        const request = {
            encryptedPatientId: id
        }
        this.httpService
            .post<Patient>(ApiResources.getURI(ApiResources.labs.base, ApiResources.labs.find), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { }))
            .subscribe(
                (response: Patient) => {
                    this.patient = response;
                    if (this.patient.relation == "Husband") {
                        this.relationType = "W/O";
                    } else if (this.patient.relation == "Wife") {
                        this.relationType = "H/O";
                    } else if (this.patient.relation == "Father" && this.patient.gender == "M") {
                        this.relationType = "S/O";
                    } else if (this.patient.relation == "Father" && this.patient.gender == "F") {
                        this.relationType = "D/O";
                    } else if (this.patient.relation == "Sister" && this.patient.gender == "F") {
                        this.relationType = "Sis /O";
                    } else if (this.patient.relation == "Sister" && this.patient.gender == "M") {
                        this.relationType = "B /O";
                    } else if (this.patient.relation == "Brother" && this.patient.gender == "F") {
                        this.relationType = "Sis /O";
                    } else if (this.patient.relation == "Brother" && this.patient.gender == "M") {
                        this.relationType = "B /O";
                    } else if (this.patient.relation == "Son" && this.patient.gender == "M") {
                        this.relationType = "F /O";
                    } else if (this.patient.relation == "Son" && this.patient.gender == "F") {
                        this.relationType = "M /O";
                    } else if (this.patient.relation == "Daughter" && this.patient.gender == "M") {
                        this.relationType = "F /O";
                    } else if (this.patient.relation == "Daughter" && this.patient.gender == "F") {
                        this.relationType = "M /O";
                    }
                });

    }
    private prepareDocument(document: any) {
        this.logoDocument = document;
        this.logoDocument.headerFormUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${document.headerFormUrl}`);
        this.logoDocument.bottomFormUrl = this.sanitizer.bypassSecurityTrustResourceUrl(`${ApiResources.getURI(ApiResources.resources.base, ApiResources.resources.getProfileImage)}?imagePath=${document.bottomFormUrl}`);
    }

    private fetchCommonSettings() {
        this.loading = true;
        const request = {
        }
        request["name"] = "Purpose Of Print Terms And Conditions";
        request["type"] = "Lab";

        this.httpService.get<Array<Setting>>(ApiResources.getURI(ApiResources.setting.base, ApiResources.setting.fetch), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => this.loading = false))
            .subscribe((response: Array<Setting>) => {
                this.isPrintTermsAndConditions = response[0]?.active ? response[0].active : false;
            });
    }
    onChangeFooter(type: boolean) {
        this.isFooter = type;
    }
    private fetchTemplateDetail(id: number) {
        const request = {
            headerId: id
        };
        this.httpService.get(ApiResources.getURI(ApiResources.labs.base, ApiResources.labs.fetchLabTemplateDetail), request)
            .pipe(takeUntil(this.page.unSubscribe))
            .pipe(finalize(() => { }))
            .subscribe(
                (response: Array<LabTemplateDetail>) => {
                    this.templateDetail = response;
                    this.templateDetail?.forEach((item) => {
                        if (item.parameterName) {
                            this.reports?.templates?.forEach((template) => {
                                var parameter = template?.parameters.find(x => x.labParameterHeaderId == item.labParameterHeaderId);
                                this.templateDetailsOrder.push(parameter);
                            })
                        } else if (item.componentName) {
                            this.reports?.templates?.forEach((template) => {
                                var component = template?.components.find(x => x.labComponentHeaderId == item.labComponentHeaderId);
                                this.templateDetailsOrder.push(component);
                            })
                        }
                    })
                });
    }
}